import { FootnoteComponent, FootnoteConfig } from './footnotesTypes';

const GLOBALELEM_FOOTNOTE_REFERENCE_COMPONENT = 'globalelem-footnote-reference-component';
const GLOBALELEM_FOOTNOTE_COMPONENT = 'globalelem-footnote-component';

export const GLOBALELEM_FOOTNOTE_COMPONENTS: FootnoteComponent[] = [
  {
    tag: GLOBALELEM_FOOTNOTE_COMPONENT,
    loader: () => import('@dh-io-globalelem/footnote-component/dist/loader'),
  },
  {
    tag: GLOBALELEM_FOOTNOTE_REFERENCE_COMPONENT,
    loader: () => import('@dh-io-globalelem/footnote-reference-component/dist/loader'),
  },
];

//Loads globalelem footnote components if they are not available yet
export const loadFootnoteComponent = async (component: FootnoteComponent): Promise<void> => {
  if (!window.customElements.get(component.tag)) {
    if (component.loader) {
      const { defineCustomElements } = await component.loader();
      await defineCustomElements(window);
    }
  }
};

export const loadFootnoteComponents = async (): Promise<void> => {
  for (const component of GLOBALELEM_FOOTNOTE_COMPONENTS) {
    await loadFootnoteComponent(component);
  }
};

const referencedFootnoteIds: Record<string, string>[] = [];

// Sets a unique reference of the globalElement footnote reference component
export const setUniqueReference = (componentId: string): string => {
  const uniqueId: number = referencedFootnoteIds.filter((el: Record<string, string>) => el.id === componentId).length;
  return `${uniqueId + 1}`;
};

// Sets the footnote reference of the globalElement footnote reference component
export const setReference = (dataComponentName: string, componentId: string): string => {
  const moduleId = `${dataComponentName.replace('hp-', '').split('-').join('')}`;
  const reference = `hp_${moduleId}${setUniqueReference(componentId)}_${componentId}`;
  return reference.toLowerCase();
};

// Sets the footnote moduleId of the globalElement footnote reference component
export const setModuleId = (componentId: string) => {
  return `hp-${componentId}`;
};

// Process the footnotes and add the footnote reference to the footnote
export const processFootnotes = (
  componentElement: HTMLElement,
  payload: any,
  componentId: string,
  dataComponentName: string,
): FootnoteConfig => {
  loadFootnoteComponents();
  const moduleId = setModuleId(componentId);
  const isLocalScope = payload.footnotes.some((footnote: any) => footnote.local);
  componentElement.querySelectorAll('[data-footnote-id]').forEach((element) => {
    const id = element.getAttribute('data-footnote-id');

    const footnote = payload.footnotes.find((footnote: any) => footnote.id === id);

    const newReference = `<${GLOBALELEM_FOOTNOTE_REFERENCE_COMPONENT} 
          content="${footnote.text.replace(/"/g, '&quot;')}"
          reference="${setReference(dataComponentName, componentId)}"
          ${footnote.local ? `module-id="${setModuleId(componentId)}"` : ''}
        ></${GLOBALELEM_FOOTNOTE_REFERENCE_COMPONENT}>`;

    if (footnote) {
      element.insertAdjacentHTML('beforeend', newReference);

      referencedFootnoteIds.push({ id: componentId });
    }
  });
  return {
    moduleId,
    isLocalScope,
  };
};
