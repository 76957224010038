import { MediaQuery, MediaQueryChangeEvent } from '@workbench/core';
import { computed, reactive } from 'vue';

export type MediaState = {
  viewport: string;
};

let wbMediaQueryService: unknown;

export const initialMediaState: MediaState = {
  viewport: '',
};

export const mediaState = reactive(initialMediaState);

export const mediaStateViewport = computed(
  () => mediaState.viewport == 'mq4' || mediaState.viewport == 'mq5' || mediaState.viewport == 'mq6',
);

export const updateMedia = (event: CustomEvent<MediaQueryChangeEvent>): void => {
  const breakpoint = event?.detail?.current;
  setMedia(breakpoint);
};

export const setMedia = (breakpoint: MediaQuery): void => {
  mediaState.viewport = breakpoint;
};

export const importMediaQueryService = async () => {
  if (!wbMediaQueryService) {
    const { MediaQueryService } = await import('@workbench/core');
    wbMediaQueryService = new MediaQueryService(window);
    setMedia(wbMediaQueryService.getCurrentMediaQuery());
  }
};

export const addWbResizeListener = async () => {
  await importMediaQueryService();
  window.addEventListener('wbresize', updateMedia as EventListenerOrEventListenerObject);
};

export const removeWbResizeListener = () => {
  window.removeEventListener('wbresize', updateMedia as EventListenerOrEventListenerObject);
};
