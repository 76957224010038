import { Footnote } from '@shared/src/footnotes/footnotesTypes';

// To be removed when Workbench releases theming
// https://pages.git.i.mercedes-benz.com/dh-io-workbench/documentation/v7/foundations/colour-system/
declare global {
  interface Window {
    aemNamespace: AemNamespace;
  }
  interface AemNamespace {
    pageEnvironmentVariables: ConnectionAemPageEnvironmentState;
  }
  interface ConnectionAemPageEnvironmentState {
    apiUri: string;
    brand: {
      subBrand: string;
    };
    country: string;
    experience: string;
    language: string;
    languageDirection: string;
    runMode: string;
    stage: string;
    stageVariant: string;
    cscsApi: {
      apiPath: string;
      applicationName: string;
    };
    vehicleData: {
      modelSeries: string;
      subBrand: string;
      [key: string]: unknown;
    };
  }
}

export type CampaignCard = {
  id: string;
  title: string;
  subline: string;
  image: Image;
  links: CTA[];
  notes: string;
  personalisationBg?: boolean;
  personalised?: boolean;
  brand?: string;
};

type Image = {
  source: string;
  sources?: Set<string, string>;
  description: string;
};

type CTA = {
  label: string;
  link: string;
  target: string;
};

export type CampaignsPayload = {
  card: CampaignCard[];
  grid: string;
  headline: string;
  footnotes: Footnote[];
};

export type CampaignsJSON = {
  [key: string]: string;
  titleCard1: string;
  brandCard1?: string;
  sublineCard1: string;
  linksCard1: CTA;
  notesCard1: string;
  imageCard1: Image;
  personalisationBgCard1: boolean;
  titleCard2: string;
  brandCard2?: string;
  sublineCard2: string;
  linksCard2: CTA;
  notesCard2: string;
  imageCard2: Image;
  personalisationBgCard2: boolean;
  titleCard3: string;
  brandCard3?: string;
  sublineCard3: string;
  linksCard3: CTA;
  notesCard3: string;
  imageCard3: Image;
  personalisationBgCard3: boolean;
  titleCard4: string;
  brandCard4?: string;
  sublineCard4: string;
  linksCard4: CTA;
  notesCard4: string;
  imageCard4: Image;
  personalisationBgCard4: boolean;
  titleCard5: string;
  brandCard5?: string;
  sublineCard5: string;
  linksCard5: CTA;
  notesCard5: string;
  imageCard5: Image;
  personalisationBgCard5: boolean;
  grid: string;
  headline: string;
  footnotes: Footnote[];
};

type ImgSize = {
  width: number;
  height: number;
};

export const OWC_PERSONALISATION_CONNECTION = 'OWC_PERSONALISATION_DATA';

export type OWCPersonalisationState = OWCPersonalisationData[];

export type OWCPersonalisationData = {
  [key: string]: unknown;
  componentId: string;
  personalisationData: Record<string, unknown>[];
};
