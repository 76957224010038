<script setup lang="ts">
import { computed, onMounted, ref, inject } from 'vue';
import { CampaignCard, CTA } from '@/services/dataService/dataService.d';
import { triggerAnalyticsEvent } from '@/services/trackingService';
import { toSnakeCase } from '@shared/formatter';
import { getAssetSrc } from '@shared/environment';

const props = defineProps<{
  campaignsCardData: CampaignCard;
  index: number;
  grid: string;
  isDesktop: boolean;
}>();
const env = ref('');
const isOnContrast = inject('isOnContrast', false);

const getCtaClass = computed(() => {
  return (index: number) => {
    return index === 0
      ? 'wbx-button wbx-button--primary wbx-button--medium'
      : 'wbx-link wbx-link--standalone';
  };
});

const isLargeFirstImg = computed(() => {
  return props.index === 0 && props.grid === '5' && props.isDesktop;
});

const trackCTA = async (
  campaignsCtaData: CTA,
  index: number,
): Promise<void> => {
  const ctaPosition = index === 0 ? 'primary' : 'link';
  await triggerAnalyticsEvent({
    category: 'link',
    action: 'campaigns',
    label: `card_${props.index + 1}.${ctaPosition}.${toSnakeCase(campaignsCtaData.label)}`,
    URL: campaignsCtaData.link,
  });
};
const imgSource = computed(() => {
  if (props.campaignsCardData?.image?.sources) {
    return isLargeFirstImg.value
      ? props.campaignsCardData?.image.sources?.['mq6']
      : props.campaignsCardData?.image.sources?.['mq1'];
  } else if (props.campaignsCardData?.image?.source) {
    return props.campaignsCardData?.image.source;
  }
  return '';
});
const mediaQueries = [1440, 1280, 1024, 768, 480];
const brand = inject('brand');
const shouldShowTag = computed(() => {
  return brand !== 'amg' && brand !== 'maybach';
});
const dataOnMode = brand === 'amg' ? 'main' : 'contrast';
onMounted(() => {
  env.value =
    window.aemNamespace?.pageEnvironmentVariables?.stageVariant || 'test';
});
</script>

<template>
  <article
    class="hp-campaigns__card"
    :class="{
      'hp-campaigns__card--on-contrast': isOnContrast,
      'hp-campaigns__card--large': isLargeFirstImg,
    }"
  >
    <div
      v-if="props.campaignsCardData?.brand && shouldShowTag"
      class="hp-campaigns__subbrand"
      :style="`background-image:url(${getAssetSrc(
        env,
        `images/brands/tag-background.svg`,
      )})`"
    >
      <img
        :src="
          getAssetSrc(
            env,
            `images/brands/mercedes-${props.campaignsCardData?.brand}/logo.svg`,
          )
        "
        :alt="props.campaignsCardData?.brand"
      />
    </div>
    <div class="hp-campaigns__card-container">
      <div class="hp-campaigns__image">
        <div
          class="hp-campaigns__ratio"
          :class="{
            'hp-campaigns-personalisation':
              props.campaignsCardData?.personalisationBg,
            'hp-campaigns__ratio--large': isLargeFirstImg,
          }"
          :style="
            props.campaignsCardData?.personalisationBg
              ? {
                  'background-image': `url(${getAssetSrc(
                    env,
                    'images/brands/mercedes-benz/background/personsalisation-background-1109.png',
                  )})`,
                }
              : {}
          "
        >
          <picture>
            <source
              v-for="media in mediaQueries"
              :key="media"
              :media="`(min-width: ${media}px)`"
              :srcset="imgSource"
            />

            <img
              class="hp-campaigns__ratio-img"
              :src="imgSource"
              :alt="props.campaignsCardData?.image.description"
              :aria-label="props.campaignsCardData?.image.description"
            />
          </picture>
        </div>
      </div>
      <div class="hp-campaigns__content">
        <h3 class="hp-campaigns__title" v-html="campaignsCardData?.title"></h3>
        <p
          class="hp-campaigns__subline"
          v-html="campaignsCardData?.subline"
        ></p>
        <div class="hp-campaigns__ctas" :data-on="dataOnMode">
          <a
            v-for="(campaignsCtaData, index) in campaignsCardData.links"
            @click="trackCTA(campaignsCtaData, index)"
            :key="campaignsCtaData.label"
            :href="campaignsCtaData?.link"
            :target="campaignsCtaData?.target"
            :class="[getCtaClass(index), 'hp-campaigns__cta']"
            tabindex="0"
          >
            {{ campaignsCtaData?.label }}</a
          >
        </div>
      </div>
    </div>

    <aside v-if="campaignsCardData?.notes" class="hp-campaigns__notes">
      <p v-html="campaignsCardData?.notes"></p>
    </aside>
  </article>
</template>

<style lang="scss">
@import '@workbench/core/dist/scss/_utility.scss';

$moduleName: 'hp-campaigns';

.#{$moduleName} {
  &__card {
    position: relative;
    min-height: 100%;
    border-radius: var(--wb-border-radius-softer, 10px);
    overflow: hidden;
    background: linear-gradient(
      transparent 0,
      var(--wb-color-shape-fixed-black, #000 30%),
      var(--wb-color-shape-fixed-black, #000 100%)
    );
    &--on-contrast {
      border: 1px solid var(--wb-color-border-weaker-default, #262626);
    }
  }
  &__card-container {
    position: relative;
    margin-block-end: var(--wb-spacing-static-medium);

    &:hover {
      .hp-campaigns__ratio-img {
        transform: scale(107%);
      }
    }
  }
  &__image {
    padding-block-end: 40%;
    @include breakpoint-from(mq2) {
      padding-block-end: 25%;
    }
    @include breakpoint-from(mq3) {
      padding-block-end: 35%;
    }
    @include breakpoint-from(mq4) {
      padding-block-end: 50%;
    }
    @include breakpoint-from(mq5) {
      padding-block-end: 40%;
    }
    @include breakpoint-from(mq6) {
      padding-block-end: 30%;
    }
  }
  &__card--large .hp-campaigns__image {
    @include breakpoint-from(mq4) {
      padding-block-end: 23%;
    }
    @include breakpoint-from(mq5) {
      padding-block-end: 18%;
    }
    @include breakpoint-from(mq6) {
      padding-block-end: 12.7%;
    }
  }
  &__ratio {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 1 / 1;
    &.hp-campaigns-personalisation {
      background-size: cover;
    }

    &-img {
      color: var(--wb-color-shape-fixed-white);
      text-indent: var(--wb-spacing-s);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      transition: transform 600ms cubic-bezier(0.35, 0, 0, 1);
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    padding-block-start: var(--wb-spacing-static-hugest);
    padding-inline-start: var(--wb-spacing-static-small);
    padding-inline-end: var(--wb-spacing-static-small);
    color: var(--wb-color-text-fixed-white, #fff);
    width: 100%;
    background: linear-gradient(
      0deg,
      var(--wb-color-shape-fixed-black, #000) 72%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow-wrap: break-word;
  }
  &__title {
    @include wb-heading(
      'm',
      (
        responsive: true,
      )
    );
    margin: 0 0 var(--wb-spacing-responsive-tiniest) 0;
  }
  &__subbrand {
    width: 173px;
    height: 67px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    [dir='rtl'] & {
      transform: scaleX(-1);
    }
    img {
      position: relative;
      // width of the "wave" in the svg
      margin-bottom: 7px;
      margin-right: 13px;
      max-width: 89px;
      shape-rendering: geometricPrecision;
      [dir='rtl'] & {
        transform: scaleX(-1);
      }
    }
  }
  &__subline {
    @include wb-text(
      'm',
      (
        responsive: true,
      )
    );
    margin: 0;
    margin-block-end: var(--wb-spacing-static-small);
    max-width: 418px;
  }
  &__ctas {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: var(--wb-spacing-static-small);
  }
  &__cta {
    white-space: pre;
    flex: initial;
  }
  &__notes {
    position: relative;
    color: var(--wb-color-text-fixed-white, #fff);
    @include wb-text('s');
    padding: var(--wb-spacing-static-smaller) var(--wb-spacing-static-small);
    > * {
      margin: 0;
    }
  }
  &__card:has(.hp-campaigns__notes) {
    .hp-campaigns__card-container {
      margin-block-end: calc(var(--wb-spacing-static-medium) - 24px);
    }
  }
  &__card--large {
    @include breakpoint-from(mq4) {
      .hp-campaigns__ratio {
        aspect-ratio: 2 / 1;
      }
    }
  }
}
</style>
