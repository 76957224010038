import { Ref } from 'vue';
import { CampaignCard, CampaignsJSON, CampaignsPayload } from './dataService.d';
import { v4 as uuidv4 } from 'uuid';

export const manageRegistryData = (payload: CampaignsJSON): CampaignsPayload => {
  const card: Array<CampaignCard> = [];

  for (let index = 1; index <= 5; index++) {
    const titleKey = `titleCard${index}`;
    const sublineKey = `sublineCard${index}`;
    const imageKey = `imageCard${index}`;
    const linksKey = `linksCard${index}`;
    const notesKey = `notesCard${index}`;
    const personalisationBgKey = `personalisationBgCard${index}`;
    const brandKey = `brandCard${index}`;

    if (titleKey in payload && imageKey in payload) {
      card.push({
        id: uuidv4(),
        title: payload[titleKey],
        subline: payload[sublineKey],
        image: payload[imageKey],
        links: payload[linksKey],
        notes: payload[notesKey],
        personalisationBg: payload[personalisationBgKey],
        brand: payload[brandKey],
      });
    }
  }

  const CampaignsData: CampaignsPayload = {
    card,
    grid: card.length < 5 && payload.grid === '5' ? '3' : payload.grid,
    headline: payload.headline,
    footnotes: payload.footnotes,
  };

  return CampaignsData;
};

export const managePersonalisationData = (personalisationPayload: CampaignCard[], payload: Ref<CampaignsPayload>) => {
  const payloadWithIds = personalisationPayload.map((card) => ({
    ...card,
    image:
      (typeof card.image) === 'string' ? {
        source: card.image
      } : card.image
    ,
    id: uuidv4(),
    personalised: true,
  }));
  payload.value.card.unshift(...payloadWithIds);
};
