<script setup lang="ts">
import { onMounted, ref, Ref, nextTick, onUnmounted, provide } from 'vue';
import CampaignsItems from './components/campaigns-items/campaigns-items.vue';
import CampaignsHeadline from './components/campaigns-headline/campaigns-headline.vue';
import { DataProps } from '@shared/types/types.d';
import { initializeTracking } from '@shared/tracking/trackingService';
import {
  CampaignCard,
  CampaignsPayload,
  OWC_PERSONALISATION_CONNECTION,
  OWCPersonalisationData,
  OWCPersonalisationState,
} from '@/services/dataService/dataService.d';
import { managePersonalisationData } from '@/services/dataService/dataService';
import { processFootnotes } from '@shared/footnotes/footnotes';
import { FootnoteConfig } from '@shared/footnotes/footnotesTypes';
import { initializeStore } from '@seamless/store';
import {
  addWbResizeListener,
  removeWbResizeListener,
} from '@shared/mediaQuery/mediaQueryService';

const props = defineProps<{
  componentId: string;
  componentName: string;
  data: DataProps;
}>();

const payload = ref(props.data.payload as CampaignsPayload);
const brand = props.data.brand;
const componentElement: Ref<HTMLElement> = ref({} as HTMLElement);
const subscription: Ref<any> = ref({});
const personalisationPayload: Ref<CampaignCard[]> = ref({} as CampaignCard[]);
const footnoteConfig: Ref<FootnoteConfig> = ref({
  moduleId: '',
  isLocalScope: false,
});

provide('isOnContrast', brand === 'amg');
provide('brand', brand);
onMounted(async () => {
  await addWbResizeListener();
  initializeTracking();
  listenToPersonalisation();
  await nextTick();
  addFootnotes();
});

onUnmounted(() => {
  unsubscribeStore();
  removeWbResizeListener();
});

const unsubscribeStore = () => {
  if (subscription.value && subscription.value.unsubscribe) {
    subscription.value.unsubscribe();
  }
};

const addFootnotes = (): void => {
  if (payload.value.footnotes) {
    footnoteConfig.value = processFootnotes(
      componentElement.value,
      payload.value,
      props.componentId,
      props.componentName,
    );
  }
};

const listenToPersonalisation = (): void => {
  const store = initializeStore();
  subscription.value = store.subscribe(
    OWC_PERSONALISATION_CONNECTION,
    (state: OWCPersonalisationState) => {
      const personalisation = state.filter(
        (el: OWCPersonalisationData) => el.componentId === props.componentId,
      );
      if (personalisation.length > 0) {
        personalisationPayload.value = personalisation[0]
          .personalisationData as unknown as CampaignCard[];
        managePersonalisationData(personalisationPayload.value, payload);
        unsubscribeStore();
      }
    },
  );
};
</script>

<template>
  <div ref="componentElement" class="hp-campaigns">
    <CampaignsHeadline
      v-if="payload.headline"
      :campaignsHeadlineData="payload.headline"
    />
    <CampaignsItems :campaignsItemsData="payload.card" :grid="payload.grid" />
  </div>
  <div v-if="footnoteConfig.isLocalScope" class="hp-campaigns__footnotes">
    <globalelem-footnote-component
      :module-id="footnoteConfig.moduleId"
    ></globalelem-footnote-component>
  </div>
</template>

<style lang="scss">
@import '@workbench/core/dist/scss/_utility.scss';

$moduleName: 'hp-campaigns';

[data-component-name='hp-campaigns'] {
  @include breakpoint-from(mq5) {
    min-height: 830px;
  }
  @include breakpoint-from(mq6) {
    min-height: 900px;
  }
}

.#{$moduleName} {
  background: var(--wb-color-section-main);
  padding-block-end: var(--wb-spacing-responsive-large);
  &__footnotes {
    width: var(--wb-grid-width);
    margin: 0 auto;
  }
}
</style>
