import { getTrackingDispatchers, TrackingEvent } from '@owc/connection-aem-tracking';

interface Action<T = unknown> {
  type: T;
}

interface AnyAction extends Action {
  [extraProps: string]: unknown;
}

export type OwcTrackingDetails = {
  name?: string;
  category: string;
  action: string;
  label: string;
  URL?: string;
};

type OwcTrackingDispatchers = {
  pushTrackingEvent: (trackingEvent: TrackingEvent) => AnyAction;
};

let analytics: OwcTrackingDispatchers;

export const buildTrackingEvent = (details: OwcTrackingDetails, version: string, app: string): TrackingEvent => {
  return {
    event: 'click',
    eventDetail: details,
    application: {
      id: app || 'aem',
      version,
      parentApplicationId: app || 'aem',
    },
  } as TrackingEvent;
};

export const initializeTracking = async (): Promise<void> => {
  analytics = await getTrackingDispatchers();
};

export const pushAnalyticsEvent = async (
  eventInfo: OwcTrackingDetails,
  version: string,
  app: string = '',
): Promise<void> => {
  if (!analytics) {
    await initializeTracking();
  }
  const event = buildTrackingEvent(eventInfo, version, app);
  analytics.pushTrackingEvent(event);
};
