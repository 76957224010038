import App from './App.vue';
import type { LoaderFn, CreateAppFn } from '$app/config';
import { manageRegistryData } from '@/services/dataService/dataService';
import { createSSRApp, createApp as createVueApp, App as VueApp } from 'vue';
import { CampaignsJSON } from '@/services/dataService/dataService.d';

export const componentName = 'hp-campaigns';

const createApp: CreateAppFn<unknown, unknown> = async (renderMode, componentId, data) => {
  let app: VueApp<Element>;
  if (import.meta.env.SSR || renderMode !== 'csr') {
    app = createSSRApp(App, { componentId,  componentName, data });
  } else {
    // fallback to CSR (the loaderDataFn might be required to mimic SSR behaviour if it exists)
    app = createVueApp(App, { componentId, componentName, data });
  }

  return app;
};

const loader: LoaderFn<unknown, unknown> = async (context) => {
  return { payload: manageRegistryData(context.payload as CampaignsJSON), brand: context.pageAEMContext?.brand?.subBrand ?? '' };
};

export default {
  createApp,
  loader,
};
