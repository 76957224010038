<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { CampaignCard } from '@/services/dataService/dataService.d';
import {
  mediaState,
  mediaStateViewport,
} from '@shared/mediaQuery/mediaQueryService';
import CampaignsCard from '../campaigns-card/campaigns-card.vue';

const props = defineProps<{
  campaignsItemsData: CampaignCard[];
  grid: string;
}>();

const sliderRef = ref();
const sliderComponent = ref<string>('div');
const sliderItemComponent = ref<string>('div');
const isDesktop = ref(true);

const waitForAnimationFrame = () => {
  return new Promise((resolve) => {
    requestAnimationFrame((timestamp) => {
      resolve(timestamp);
    });
  });
};

watch(
  () => props.campaignsItemsData,
  async (_newData: CampaignCard[], _oldData: CampaignCard[]) => {
    const slider = sliderRef.value;
    if (slider && typeof slider.scrollToItem === 'function') {
      await slider.update();
      await waitForAnimationFrame();
      await slider.scrollToItem(1);
    }
  },
  { deep: true },
);

watch(
  () => [mediaState.viewport, mediaStateViewport.value],
  ([newMediaState, newMediaStateViewport]) => {
    if (newMediaState) {
      isDesktop.value = newMediaStateViewport;
      sliderComponent.value = isDesktop.value ? 'div' : 'wb7-slider';
      sliderItemComponent.value = isDesktop.value ? 'div' : 'wb7-slider-item';
    }
  },
);

const filteredItems = computed(() => {
  return props.campaignsItemsData.slice(0, Number(props.grid));
});
</script>

<template>
  <div class="hp-campaigns__grid-container">
    <component
      :class="{
        'hp-campaigns__grid': isDesktop,
        'hp-campaigns__slider': !isDesktop,
      }"
      ref="sliderRef"
      grid-gutter
      off-grid
      show-arrow-navigation
      show-dot-navigation
      scroll-snap
      :grid="grid"
      :is="sliderComponent"
    >
      <component
        class="hp-campaigns__item"
        v-for="(campaignsCardData, index) in filteredItems"
        :key="`${campaignsCardData.id}-${index === 0 && grid === '5' ? 'landscape' : 'portrait'}`"
        :is="sliderItemComponent"
        mq1="11"
        mq3="6"
      >
        <CampaignsCard
          :campaignsCardData="campaignsCardData"
          :index="index"
          :grid="grid"
          :isDesktop="isDesktop"
        />
      </component>
    </component>
  </div>
</template>

<style lang="scss">
@import '@workbench/core/dist/scss/_utility.scss';

$moduleName: 'hp-campaigns';

.#{$moduleName} {
  &__grid-container:has(> div) {
    @include breakpoint-to(mq3) {
      display: grid;
      margin-inline-start: calc((100% - var(--wb-grid-width)) / 2);
      .hp-campaigns__grid,
      .hp-campaigns__slider {
        display: block;
        white-space: nowrap;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        margin-block-end: 28px;
      }
      .hp-campaigns__item {
        display: inline-block;
        height: 100%;
        width: calc(
          var(--wb-grid-column-width) * 11 + var(--wb-grid-gutter-width) * 10
        );
        margin-right: var(--wb-grid-gutter-width);
        white-space: normal;
      }
    }
    @include breakpoint-between(mq3, mq4) {
      .hp-campaigns__item {
        width: calc(
          var(--wb-grid-column-width) * 6 + var(--wb-grid-gutter-width) * 5
        );
      }
    }
  }
  &__grid {
    @include breakpoint-from(mq4) {
      width: var(--wb-grid-width);
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto auto;
      gap: var(--wb-grid-gutter-width);
    }
  }

  &__grid[grid='3'] {
    @include breakpoint-from(mq4) {
      .hp-campaigns__item:nth-child(-n + 3) {
        grid-row: 1 / 2;
      }
      .hp-campaigns__item {
        grid-column: span 4;
      }
    }
  }
  &__grid[grid='5'] {
    @include breakpoint-from(mq4) {
      .hp-campaigns__item:nth-child(-n + 2) {
        grid-row: 1 / 2;
      }
      .hp-campaigns__item:first-child {
        grid-column: 1 / span 8;
      }
      .hp-campaigns__item:nth-child(2) {
        grid-column: 9 / span 4;
      }
      .hp-campaigns__item:nth-child(n + 3):nth-child(-n + 5) {
        grid-row: 2 / 3;
      }
      .hp-campaigns__item:nth-child(3) {
        grid-column: 1 / span 4;
      }
      .hp-campaigns__item:nth-child(4) {
        grid-column: 5 / span 4;
      }
      .hp-campaigns__item:nth-child(5) {
        grid-column: 9 / 13;
      }
    }
  }
  &__slider .hp-campaigns__item:last-child {
    margin-inline-end: calc((100% - var(--wb-grid-width)) / 2);
  }
}
</style>
